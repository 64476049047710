







































































import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/api';
import { AsyncParser } from 'json2csv';
import LineChart, { ChartDataItem } from '../dataDisplayComponents/LineChart.vue';
import { namespace } from 'vuex-class';
import {
  TelemetryAction,
  TelemetryActionType,
  TelemetryState
} from '@/store/modules/telemetry/types';
import lampTelemetry from '@/constants/lamp_telemetry';
import { TelemetryIndex } from '@/types/telemetry';
import ClassicMeter from '../dataDisplayComponents/ClassicMeter.vue';
import { ControllerTypes } from '../AnalyticsCard.vue';
import control_cabinet_telemetry from '@/constants/control_cabinet_telemetry';
import { isGateway, isLamp } from '@/utils/type_check';
import circuitGroupTelemetry from '@/constants/circuit_group_telemetry';
import BaseSelect from '@/components/base/BaseSelect.vue';

const telemetry = namespace('telemetry');

@Component({
  components: {
    BaseSelect,
    LineChart,
    ClassicMeter
  }
})
/**
 * @group Analytics Card
 * Energy consumption card with
 */
export default class ConsumptionCard extends Vue {
  @Prop({
    type: Object,
    validator: (d) => isLamp(d) || isGateway(d)
  })
  readonly selectedDevice!: Models.Devices.Lamp | Models.Devices.Gateway | null;

  @Prop({ type: Object })
  readonly selectedElement!: Models.Circuits.Model | Models.Groups.Model | null;

  @Prop({ type: Object })
  readonly telemetryData!: Models.Telemetries.Telemetry | null;

  @Prop({ type: String })
  readonly telemetryControllerType!: ControllerTypes | null;

  @telemetry.State
  telemetries!: TelemetryState['telemetries'];

  @telemetry.State
  aggregatedTelemetry!: TelemetryState['aggregatedTelemetry'];

  @telemetry.State
  energyConsumption!: TelemetryState['energyConsumption'];

  @telemetry.State
  co2Emission!: TelemetryState['co2Emission'];

  @telemetry.Action(TelemetryAction.FetchDeviceTelemetriesFromTo)
  fetchDeviceTelemetriesFromTo!: TelemetryActionType['FETCH_DEVICE_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchDeviceKobize)
  fetchDeviceKobize!: TelemetryActionType['FETCH_DEVICE_KOBIZE'];

  @telemetry.Action(TelemetryAction.FetchCircuitTelemetriesFromTo)
  FetchCircuitTelemetriesFromTo!: TelemetryActionType['FETCH_CIRCUIT_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchGroupTelemetriesFromTo)
  FetchGroupTelemetriesFromTo!: TelemetryActionType['FETCH_GROUP_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchCircuitKobize)
  FetchCircuitKobize!: TelemetryActionType['FETCH_CIRCUIT_KOBIZE'];

  @telemetry.Action(TelemetryAction.FetchGroupKobize)
  FetchGroupKobize!: TelemetryActionType['FETCH_GROUP_KOBIZE'];

  deviceTelemetries: Array<Models.Telemetries.Telemetry> = [];

  elementTelemetries: Models.Telemetries.SumOfTelemetry | null = null;

  startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
    .substring(0, 10);

  endDate = new Date().toISOString()
    .substring(0, 10);

  consumption = 0;

  emission = 0;

  loading = false;

  chosenTelemetryKey: string = this.firstOption?.value ?? '';

  get chartData(): ChartDataItem[] {
    // const recordsCount = 20;
    // const unixTimeStart = new Date(this.startDate).getTime();
    // const unixTimeEnd = new Date(this.endDate).getTime();
    // const timeInterval = (unixTimeEnd - unixTimeStart) / recordsCount;

    // return this.deviceTelemetries.slice(-20).map((d, i) => ({
    //   id: Math.ceil(Math.random() * 1000),
    //   timestamp: new Date(unixTimeStart + i * timeInterval),
    //   value: Math.floor(Math.random() * 10 + 20)
    // }));
    if (!this.selectedDevice && !this.selectedElement) return [];
    if (this.selectedDevice) {
      return this.deviceTelemetries.map((t) => ({
        id: t.id as number,
        timestamp: new Date(t.timestamp),
        value: Math.abs(t.telemetry[this.chosenTelemetryKey]?.toFixed(2) ?? null),
        // value: Math.round((t.telemetry[this.chosenTelemetryKey]) / 10) / 100, // Wh to kWh with 2 digit precision
      }))
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) return -1;
          if (a.timestamp > b.timestamp) return 1;
          return 0;
        });
    }
    if (this.elementTelemetries) {
      return this.elementTelemetries.data.map((t) => ({
        id: Math.ceil(Math.random() * 1000),
        timestamp: new Date(t.timestamp),
        value: Math.abs(t[this.chosenTelemetryKey]?.toFixed(2) ?? null),
      }))
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) return -1;
          if (a.timestamp > b.timestamp) return 1;
          return 0;
        });
    }
    return [];
  }

  get chartUnit() {
    if (!this.selectedDevice && !this.selectedElement) return null;
    if (this.selectedDevice) {
      switch (this.selectedDevice.controller_type) {
        case ControllerTypes.GATEWAY_CONTROL_CABINET_1_V1:
          return control_cabinet_telemetry.GatewayControlCabinet1V1TelemetryIndices[this.chosenTelemetryKey].unit;
        case ControllerTypes.LAMP_CONTROL_CABINET_1_V1:
          return lampTelemetry.lampControlCabinet1V1TelemetryIndices[this.chosenTelemetryKey].unit;
        case 'iqrf':
          return lampTelemetry.lampMtap3V1TelemetryIndices[this.chosenTelemetryKey].unit;
        case ControllerTypes.LAMP_MTAP_7_V1:
          return lampTelemetry.lampMtap7V1TelemetryIndices[this.chosenTelemetryKey].unit;
        default:
          return lampTelemetry.lampTelemetryIndices[this.chosenTelemetryKey].unit;
      }
    }
    return circuitGroupTelemetry.circuitGroupTelemetryIndices[this.chosenTelemetryKey].unit;
  }

  get selectOptions(): { text: string, value: string }[] {
    if (!this.selectedDevice && !this.selectedElement) return [];
    const result = [] as any;
    this.lampIndices.forEach((index) => {
      result.push({
        text: this.$t(`telemetries.${index}`),
        value: index
      });
    });
    return result;
  }

  get firstOption() {
    // if (this.isControlCabinetLamp) return null;
    if (!this.selectOptions.length) return null;
    return this.selectOptions[0];
  }

  get lampIndices(): string[] {
    if (!this.selectedDevice && !this.selectedElement) return [];
    let lampIndices: string[];
    if (this.selectedDevice) {
      switch (this.selectedDevice.controller_type) {
        case 'iqrf':
          lampIndices = lampTelemetry.lampMtap3V1IndicesNames;
          break;
        case ControllerTypes.GATEWAY_CONTROL_CABINET_1_V1:
          lampIndices = control_cabinet_telemetry.GatewayControlCabinet1V1IndicesNamesShort;
          break;
        case ControllerTypes.LAMP_CONTROL_CABINET_1_V1:
          lampIndices = lampTelemetry.lampControlCabinet1V1IndicesNames;
          break;
        case ControllerTypes.LAMP_MTAP_7_V1:
          lampIndices = lampTelemetry.lampMtap7V1IndicesNames;
          break;
        default:
          lampIndices = lampTelemetry.lampIndicesNames;
          break;
      }
      return lampIndices;
    }
    lampIndices = circuitGroupTelemetry.circuitGroupIndicesNames;
    return lampIndices;
  }

  get kobizeTelemetries(): TelemetryIndex[] {
    enum ComponentKobize {
      energyConsumption = 'consumption',
      co2Emission = 'emission'
    }

    const classicMeterDataBuffer: TelemetryIndex[] = [];
    ['energyConsumption', 'co2Emission'].forEach((i) => {
      const indexMeta = lampTelemetry.lampTelemetryIndices[i];
      const value = Number(this[ComponentKobize[i]]?.toFixed(2)) ?? 0;
      const name = this.$t(`telemetries.${i}`);
      if (indexMeta.unit !== 's') {
        classicMeterDataBuffer.push({
          ...indexMeta,
          value,
          name
        });
      }
    });
    return classicMeterDataBuffer;
  }

  get minDate(): string {
    if (this.selectedElement?.createdAt) {
      return this.selectedElement.createdAt.toString()
        .substring(0, 10);
    }
    return '2000-01-01';
  }

  isCircuit(element: Models.Circuits.Model | Models.Groups.Model) {
    if (!element) return null;
    return Object.prototype.hasOwnProperty.call(element, 'group_id');
  }

  selectDate(startOrEnd: 'start' | 'end', date: string) {
    if (startOrEnd === 'start') {
      this.startDate = date;
    } else {
      this.endDate = date;
    }
  }

  fetchTelemetries() {
    if (!this.selectedDevice && !this.selectedElement) return;
    // if (this.selectedDevice && this.isControlCabinetLamp) return;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const fromDate = new Date(this.startDate);
    fromDate.setHours(hours);
    fromDate.setMinutes(minutes);
    fromDate.setSeconds(seconds);
    const toDate = new Date(this.endDate);
    toDate.setHours(hours);
    toDate.setMinutes(minutes);
    toDate.setSeconds(seconds);

    const timestampFrom = fromDate.toISOString();
    const timestampTo = toDate.toISOString();

    this.loading = true;

    if (this.selectedDevice) {
      this.fetchDeviceTelemetriesFromTo({
        deviceId: this.selectedDevice.object_id,
        timestampFrom,
        timestampTo
      })
        .then(() => {
          this.deviceTelemetries = this.telemetries as Models.Telemetries.LampMtap2V1Telemetry[] | Models.Telemetries.LampVe2V1Telemetry[] | Models.Telemetries.LampControlCabinet1V1Telemetry[];
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (this.selectedElement) {
      if (this.isCircuit(this.selectedElement)) {
        this.FetchCircuitTelemetriesFromTo({
          objectId: this.selectedElement.uuid!,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.elementTelemetries = this.aggregatedTelemetry as Models.Telemetries.SumOfTelemetry;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.FetchGroupTelemetriesFromTo({
          objectId: this.selectedElement.uuid!,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.elementTelemetries = this.aggregatedTelemetry as Models.Telemetries.SumOfTelemetry;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  fetchConsumption() {
    if (!this.selectedDevice && !this.selectedElement) return;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const fromDate = new Date(this.startDate);
    fromDate.setHours(hours);
    fromDate.setMinutes(minutes);
    fromDate.setSeconds(seconds);
    const toDate = new Date(this.endDate);
    toDate.setHours(hours);
    toDate.setMinutes(minutes);
    toDate.setSeconds(seconds);

    const timestampFrom = fromDate.toISOString();
    const timestampTo = toDate.toISOString();

    if (this.selectedDevice) {
      this.fetchDeviceKobize({
        device: this.selectedDevice,
        queryParams: {
          startDate: timestampFrom,
          endDate: timestampTo
        }
      })
        .then(() => {
          if (this.selectedDevice) {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          } else {
            this.consumption += this.energyConsumption;
            this.emission += this.co2Emission;
          }
        });
    } else if (this.selectedElement) {
      if (this.isCircuit(this.selectedElement)) {
        this.FetchCircuitKobize({
          circuit: this.selectedElement as Models.Circuits.Model,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          });
      } else {
        this.FetchGroupKobize({
          group: this.selectedElement as Models.Groups.Model,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          });
      }
    }
  }

  exportToCsv() {
    let deviceName = '';
    const {
      startDate,
      endDate
    } = this;
    let fields: string[] = [];
    let flattenedTelemetries: any[] | undefined = [];

    function downloadFile(csv: string) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${deviceName}_${startDate}_${endDate}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    // use both in chartOptions and for csv fields
    // check telemetry controllerType
    // based on controller type use different const from lampTelemetry
    if (this.selectedDevice) {
      fields = [
        'id',
        'object_id',
        'device_type',
        'timestamp',
        'controller_type',
        'set_duty',
        // 'activePower',
        // 'status',
        // 'statusMessage',
        // 'activeEnergy',
        // 'apparentPower',
        // 'apparentEnergy',
        // 'loadsidePower',
        // 'loadsideEnergy',
        // 'controlGearOperatingTime',
        // 'controlGearPowerFactor',
        // 'controlGearExternalSupplyVoltage',
        // 'controlGearExternalSupplyVoltageFrequency',
        // 'controlGearTemperature',
        // 'controlGearOutputCurrentPercent',
        // 'lightSourceVoltage',
        // 'lightSourceCurrent',
        // 'lightSourceTemperature',
        // 'lightSourceOnTime',
        // 'deltaActiveEnergy',
      ];
      deviceName = this.selectedDevice?.object_id;
      flattenedTelemetries = this.deviceTelemetries.map((t) => {
        const {
          id,
          object_id,
          device_type,
          controller_type,
          timestamp
        } = t;
        const telemetryData: any = t.telemetry;
        delete telemetryData.timestamp;
        this.lampIndices.forEach((e) => {
          telemetryData[e] = Math.abs(telemetryData[e]);
        });
        return {
          id,
          object_id,
          device_type,
          controller_type,
          timestamp,
          ...telemetryData
        };
      });
    } else if (this.selectedElement) {
      fields = [
        'timestamp',
      ];
      deviceName = this.selectedElement?.name;
      flattenedTelemetries = this.elementTelemetries?.data.map((t) => {
        const {
          timestamp
        } = t;
        const telemetryData: any = t;
        this.lampIndices.forEach((e) => {
          telemetryData[e] = Math.abs(telemetryData[e]);
        });
        return {
          timestamp,
          ...telemetryData
        };
      });
    }

    fields = fields.concat(this.lampIndices);
    const opts = { fields };
    const asyncParser = new AsyncParser(opts);
    let csv = '';
    asyncParser.processor
      .on('data', (chunk) => {
        csv += chunk.toString();
      })
      .on('end', () => downloadFile(csv))
      .on('error', (err) => console.error(err));

    asyncParser.transform
      .on('error', (err) => console.error(err));
    const data = JSON.stringify(flattenedTelemetries);
    asyncParser.input.push(data);
    asyncParser.input.push(null);
  }

  clearState() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
      .substring(0, 10);
    this.endDate = new Date().toISOString()
      .substring(0, 10);
    this.consumption = 0;
    this.emission = 0;
    this.loading = false;
    this.chosenTelemetryKey = this.firstOption?.value ?? '';
    this.deviceTelemetries = [];
    this.elementTelemetries = null;
  }

  @Watch('selectedDevice')
  onSelectedDeviceChange() {
    this.clearState();
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('selectedElement')
  onSelectedElementChange() {
    this.clearState();
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('startDate')
  onStartDateChange() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('endDate')
  onEndDateChange() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  mounted() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }
}
