




























import {
  Component, Vue, Prop, Watch, Ref
} from 'vue-property-decorator';
import * as d3 from 'd3';
import { dateTimeToStr } from '@/utils/time_format';

export interface ChartDataItem {
  id: number,
  value: number,
  timestamp: Date,
}

const isChartData = (data) => Array.isArray(data) && data.every((d) => typeof d.value === 'number' && typeof d.timestamp === 'object');

@Component({
  filters: {
    dateTimeToStr
  }
})
export default class LineChart extends Vue {
  @Ref() tooltipRef!: any

  @Prop({ type: Array, validator: isChartData, required: true }) chartData!: ChartDataItem[]

  @Prop(Number) minValue!: number

  @Prop(Number) maxValue!: number

  @Prop({ type: String, required: true }) chartId!: string

  @Prop(String) yAxisLabel!: string

  @Prop({ type: Boolean, default: false }) loading!: boolean

  margin = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 100
  }

  height = 400

  width = 800

  svg!: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>

  mouseOverGroup!: d3.Selection<SVGGElement, unknown, HTMLElement, any>

  tooltipItem: ChartDataItem = {
    id: 0,
    value: 0,
    timestamp: new Date()
  }

  // locale: any = null

  formatMinute = d3.timeFormat('%I:%M')

  formatHour = d3.timeFormat('%I:00')

  formatDay = d3.timeFormat('%d/%m')

  formatMonth = d3.timeFormat('%B')

  formatYear = d3.timeFormat('%Y');

  get xDomain() {
    return this.chartData.length ? d3.extent(this.chartData, (d) => d.timestamp) as [Date, Date] : []; // TODO
  }

  get yDomain() {
    return this.chartData.length ? d3.extent(this.chartData, (d) => d.value) as [number, number] : []; // TODO
  }

  get xScale() {
    return d3
      .scaleTime()
      .domain(this.xDomain)
      .range([0, this.width])
      .nice();
  }

  get yScale() {
    return d3
      .scaleLinear()
      .domain(this.yDomain)
      .range([this.height, 0]);
  }

  @Watch('chartData')
  onChartDataChanged(newData) {
    if (newData.length) {
      this.updateChart();
    }
  }

  multiFormat(date) {
    if (d3.timeHour(date) < date) return this.formatMinute(date);
    if (d3.timeDay(date) < date) return this.formatHour(date);
    if (d3.timeMonth(date) < date) return this.formatDay(date);
    if (d3.timeYear(date) < date) return this.formatMonth(date);
    return this.formatYear(date);
  }

  // Methods
  xAxisGenerator() {
    return (g: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
      g.attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`
      )
        .call(
          d3
            .axisBottom(this.xScale)
            .ticks(8)
            .tickFormat(this.multiFormat)
        )
        .call((g1) => g1.select('.domain').remove())
        .call((g2) => g2
          .selectAll('.tick text')
          .attr(
            'transform',
            `translate(0, ${this.margin.bottom / 4})`
          ));
    };
  }

  yAxisGenerator() {
    return (g: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
      g.attr(
        'transform',
        `translate(${this.margin.left}, ${this.margin.top})`
      )
        .call(
          d3
            .axisLeft(this.yScale)
            .ticks(8)
            // .tickFormat((d) => d.valueOf().toFixed(4))
            .tickSize(0)
            .tickPadding(4)
        )
        .call((g1) => g1.selectAll('.tick line').attr('stroke-dasharray', '3'))
        .call((g2) => g2.select('.domain').remove())
        .call((g3) => g3
          .selectAll('.tick line:not(.cloned)')
          .attr('class', 'cloned')
          .clone()
          .attr('x2', this.width)
          .attr('stroke-opacity', 0.1))
        .call((g4) => g4
          .selectAll('.tick text')
          .attr(
            'transform',
            `translate(-${this.margin.left / 4}, 0)`
          ))
        .call((g5) => g5.select('.label')
          .text(this.yAxisLabel));
    };
  }

  generateChart() {
    this.svg = d3
      .select(`#${this.chartId}`)
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `0 0 ${this.width + this.margin.left + this.margin.right} ${this.height + this.margin.bottom + this.margin.top}`
      );

    // add axes
    this.svg.append('g').attr('class', 'axis axis--x');
    this.svg.append('g').attr('class', 'axis axis--y')
      .append('text')
      .attr('class', 'label')
      .attr('x', -this.margin.left / 2)
      .attr('dy', '-1.5em')
      .style('text-anchor', 'middle')
      .attr('fill', '#03DAC5');

    // add chart line
    let stroke = '#63e2c8';

    if (this.minValue != null && this.maxValue != null) {
      this.svg.append('linearGradient')
        .attr('id', 'line-gradient')
        .attr('gradientUnits', 'userSpaceOnUse');

      stroke = 'url(#line-gradient)';
    }

    this.svg
      .append('path')
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
      .attr('stroke', stroke)
      .attr('stroke-width', 2);

    // append a rect to catch mouse movements on canvas
    this.mouseOverGroup = this.svg
      .append('g')
      .attr('class', 'mouse-over-effects')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // create vertical line to follow mouse
    this.mouseOverGroup
      .append('line')
      .attr('class', 'mouse-line')
      .style('stroke', '#1E5270')
      .style('stroke-width', 2)
      .attr('stroke-dasharray', 3)
      .style('opacity', 0)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', this.height);

    // add circle on the line
    this.mouseOverGroup
      .append('circle')
      .attr('r', 5)
      // .style('stroke', (d: any) => this.colorScale(d.key))
      .style('stroke', '#1E5270')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('opacity', '0');

    // on mouse out hide line, circle and tooltip
    const mouseOutHandler = () => {
      this.mouseOverGroup
        .select('.mouse-line')
        .style('opacity', '0');
      this.mouseOverGroup
        .select('circle')
        .style('opacity', '0');
      this.tooltipRef.style.display = 'none';
    };

    // on mouse move update tooltip content, line & circle position
    const mouseMoveHandler = (event: MouseEvent) => {
      const mouse = d3.pointer(event);
      const xDate = this.xScale.invert(mouse[0]);
      const bisect = d3.bisector((d: ChartDataItem) => d.timestamp).left;
      const idx = bisect(this.chartData, xDate);

      const data = this.chartData[idx];

      this.mouseOverGroup
        .select('.mouse-line')
        .attr('transform', `translate(${this.xScale(data.timestamp)}, 0)`);

      this.mouseOverGroup
        .select('circle')
        .attr('transform', `translate(${this.xScale(data.timestamp)}, ${this.yScale(data.value)})`);

      this.updateTooltip(mouse, event);
    };

    const mouseOverHandler = (event: MouseEvent) => {
      const mouse = d3.pointer(event);
      this.updateTooltip(mouse, event);
      this.mouseOverGroup.select('.mouse-line')
        .style('opacity', '0.2');
      this.mouseOverGroup.select('circle')
        .style('opacity', '1');
      this.tooltipRef.style.display = 'block';
    };

    this.mouseOverGroup
      .append('svg:rect')
      .attr('class', 'mouseEventsSvg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', mouseOutHandler)
      .on('mouseover', mouseOverHandler)
      .on('mousemove', mouseMoveHandler);
  }

  updateTooltip(mouse: [number, number], event: MouseEvent) {
    const xDate = this.xScale.invert(mouse[0]);
    const bisect = d3.bisector((d: ChartDataItem) => d.timestamp).left;
    const idx = bisect(this.chartData, xDate);
    this.tooltipItem = this.chartData[idx];

    // follow mouse
    const { width, height } = (d3.select(`#${this.chartId}`).node() as any).getBoundingClientRect();
    const { offsetX, offsetY } = event;

    if (width - offsetX <= 140) {
      this.tooltipRef.style.left = `${offsetX - 140}px`;
    } else {
      this.tooltipRef.style.left = `${offsetX + 20}px`;
    }

    if (height - offsetY <= 60) {
      this.tooltipRef.style.top = `${offsetY - 60}px`;
    } else {
      this.tooltipRef.style.top = `${offsetY}px`;
    }
  }

  updateChart() {
    // update gradient
    if (this.minValue != null && this.maxValue != null) {
      const gradient = this.svg
        .select('#line-gradient')
        .attr('x1', 0)
        .attr('y1', this.yScale(this.minValue)) // TODO
        .attr('x2', 0)
        .attr('y2', this.yScale(this.maxValue));

      const stopPointsUpdate: any = gradient
        .selectAll('stop')
        .data([
          { offset: '0%', color: '#63e2c8' },
          { offset: '50%', color: '#C45DB4' },
          { offset: '100%', color: '#b90000' }
        ]);

      stopPointsUpdate
        .enter()
        .append('stop')
        .merge(stopPointsUpdate)
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);
    }

    // udpdate line
    this.svg
      .select('.line')
      .datum(this.chartData)
      .transition()
      .duration(800)
      .attr(
        'd',
        d3
          .line<ChartDataItem>()
          .curve(d3.curveBasis)
          .x((d) => this.xScale(d.timestamp))
          .y((d) => this.yScale(d.value))
      );

    this.svg.select<SVGGElement>('.axis--x').call(this.xAxisGenerator());
    this.svg.select<SVGGElement>('.axis--y').call(this.yAxisGenerator());
  }

  mounted() {
    // const locale: any = await d3.json('https://cdn.jsdelivr.net/npm/d3-time-format@3/locale/pl-PL.json');
    // this.locale = d3.timeFormatDefaultLocale(locale);

    this.generateChart();
  }
}
