import { GatewayControlCabinet1V1PropertiesWithCO2, TelemetryIndicesMap } from '@/types/telemetry';

// indices to display in form of circular gauges with min and max values
const GatewayControlCabinet1V1IndicesNames = [
  'totalEnergy',
  'outputs',
  'inputs',
  'control',
  'doorOpen',
];

const GatewayControlCabinet1V1IndicesNamesShort = [
  'totalEnergy',
  'co2Emission',
];

const GatewayControlCabinet1V1TelemetryIndices: TelemetryIndicesMap<GatewayControlCabinet1V1PropertiesWithCO2> = {
  totalEnergy: {
    unit: 'kWh',
  },
  co2Emission: {
    unit: 'kg',
  },
};

export default {
  GatewayControlCabinet1V1IndicesNames,
  GatewayControlCabinet1V1IndicesNamesShort,
  GatewayControlCabinet1V1TelemetryIndices
};
