













































































import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/api';
import LineChart, { ChartDataItem } from '../dataDisplayComponents/LineChart.vue';
import { namespace } from 'vuex-class';
import {
  TelemetryAction,
  TelemetryActionType,
  TelemetryState
} from '@/store/modules/telemetry/types';
import ClassicMeter from '../dataDisplayComponents/ClassicMeter.vue';
import { ControllerTypes } from '../AnalyticsCard.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import DatePicker from '@/components/base/DatePicker.vue';

const telemetry = namespace('telemetry');

@Component({
  components: {
    DatePicker,
    BaseSelect,
    LineChart,
    ClassicMeter
  }
})
/**
 * @group Analytics Card
 * Energy consumption card with
 */
export default class ConsumptionCardGatewayMtap7V1 extends Vue {
  @Prop({
    type: Object,
  })
  readonly selectedDevice!: Models.Devices.GatewayMtap7V1 | null;

  @Prop({ type: Object })
  readonly selectedElement!: Models.Circuits.Model | Models.Groups.Model | null;

  @Prop({ type: Object })
  readonly telemetryData!: Models.Telemetries.GatewayMtap7V1Telemetry | null;

  @Prop({ type: String })
  readonly telemetryControllerType!: ControllerTypes | null;

  @telemetry.State
  telemetries!: TelemetryState['telemetries'];

  @telemetry.State
  aggregatedTelemetry!: TelemetryState['aggregatedTelemetry'];

  @telemetry.State
  energyConsumption!: TelemetryState['energyConsumption'];

  @telemetry.State
  co2Emission!: TelemetryState['co2Emission'];

  @telemetry.Action(TelemetryAction.FetchDeviceTelemetriesFromTo)
  fetchDeviceTelemetriesFromTo!: TelemetryActionType['FETCH_DEVICE_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchDeviceKobize)
  fetchDeviceKobize!: TelemetryActionType['FETCH_DEVICE_KOBIZE'];

  @telemetry.Action(TelemetryAction.FetchCircuitTelemetriesFromTo)
  FetchCircuitTelemetriesFromTo!: TelemetryActionType['FETCH_CIRCUIT_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchGroupTelemetriesFromTo)
  FetchGroupTelemetriesFromTo!: TelemetryActionType['FETCH_GROUP_TELEMETRIES_FROM_TO'];

  @telemetry.Action(TelemetryAction.FetchCircuitKobize)
  FetchCircuitKobize!: TelemetryActionType['FETCH_CIRCUIT_KOBIZE'];

  @telemetry.Action(TelemetryAction.FetchGroupKobize)
  FetchGroupKobize!: TelemetryActionType['FETCH_GROUP_KOBIZE'];

  deviceTelemetries: Array<Models.Telemetries.GatewayMtap7V1Telemetry> = [];

  elementTelemetries: Models.Telemetries.SumOfTelemetry | null = null;

  startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
    .substring(0, 10);

  endDate = new Date().toISOString()
    .substring(0, 10);

  consumption = 0;

  emission = 0;

  loading = false;

  selectedSubDeviceKey: number = this.subDeviceFirstOption?.value ?? 0;

  selectedSubDevicePropertyKey: number = this.subDevicePropertyFirstOption?.value ?? 0;

  // chosenTelemetryKey: string = this.firstOption?.value ?? '';

  get subDeviceOptions(): { text: string, value: number }[] {
    if (!this.selectedDevice && !this.selectedElement) return [];
    return this.telemetryData!.telemetry.devices.map((d) => ({
      text: d.device_name,
      value: d.position
    }));
  }

  get subDeviceFirstOption(): { text: string, value: number } | null {
    if (!this.subDeviceOptions.length) return null;
    return this.subDeviceOptions[0];
  }

  get subDevice(): Models.Telemetries.DynamicSubDeviceProperties | undefined {
    return this.telemetryData!.telemetry.devices.find((t) => t.position === this.selectedSubDeviceKey);
  }

  get subDevicePropertyOptions(): { text: string, value: number }[] {
    if (!this.selectedDevice && !this.selectedElement && !this.subDevice) return [];
    return this.subDevice!.telemetry.map((p) => ({
      text: p.name,
      value: p.position
    }));
  }

  get subDevicePropertyFirstOption(): { text: string, value: number } | null {
    if (!this.subDevicePropertyOptions.length) return null;
    return this.subDevicePropertyOptions[0];
  }

  get subDeviceProperty(): Models.Telemetries.DynamicProperty | undefined {
    if (!this.subDevice) return undefined;
    return this.subDevice.telemetry.find((t) => t.position === this.selectedSubDevicePropertyKey);
  }

  get chartUnit(): string {
    if (!this.subDeviceProperty) return '';
    return this.subDeviceProperty.unit ?? '';
  }

  get chartData(): ChartDataItem[] {
    if (!this.selectedDevice && !this.selectedElement) return [];
    if (!this.selectedDevice || !this.subDevice || !this.subDeviceProperty || !this.deviceTelemetries) return [];
    return this.deviceTelemetries.map((t): ChartDataItem => {
      const property = t.telemetry.devices
        .find((d) => d.position === this.selectedSubDeviceKey)!.telemetry
        .find((p) => p.position === this.selectedSubDevicePropertyKey)!;
      return {
        id: t.id as number,
        timestamp: new Date(t.timestamp),
        value: +property.value
      };
    })
      .sort((a, b) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
      });
  }

  // get kobizeTelemetries(): TelemetryIndex[] {
  //   enum ComponentKobize {
  //     energyConsumption = 'consumption',
  //     co2Emission = 'emission'
  //   }
  //
  //   const classicMeterDataBuffer: TelemetryIndex[] = [];
  //   ['energyConsumption', 'co2Emission'].forEach((i) => {
  //     const indexMeta = lampTelemetry.lampTelemetryIndices[i];
  //     const value = Number(this[ComponentKobize[i]]?.toFixed(2)) ?? 0;
  //     const name = this.$t(`telemetries.${i}`);
  //     if (indexMeta.unit !== 's') {
  //       classicMeterDataBuffer.push({
  //         ...indexMeta,
  //         value,
  //         name
  //       });
  //     }
  //   });
  //   return classicMeterDataBuffer;
  // }

  get minDate(): string {
    if (this.selectedElement?.createdAt) {
      return this.selectedElement.createdAt.toString()
        .substring(0, 10);
    }
    return '2000-01-01';
  }

  isCircuit(element: Models.Circuits.Model | Models.Groups.Model) {
    if (!element) return null;
    return Object.prototype.hasOwnProperty.call(element, 'group_id');
  }

  selectDate(startOrEnd: 'start' | 'end', date: string) {
    if (startOrEnd === 'start') {
      this.startDate = date;
    } else {
      this.endDate = date;
    }
  }

  fetchTelemetries() {
    if (!this.selectedDevice && !this.selectedElement) return;
    // if (this.selectedDevice && this.isControlCabinetLamp) return;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const fromDate = new Date(this.startDate);
    fromDate.setHours(hours);
    fromDate.setMinutes(minutes);
    fromDate.setSeconds(seconds);
    const toDate = new Date(this.endDate);
    toDate.setHours(hours);
    toDate.setMinutes(minutes);
    toDate.setSeconds(seconds);

    const timestampFrom = fromDate.toISOString();
    const timestampTo = toDate.toISOString();

    this.loading = true;

    if (this.selectedDevice) {
      this.fetchDeviceTelemetriesFromTo({
        deviceId: this.selectedDevice.object_id,
        timestampFrom,
        timestampTo
      })
        .then(() => {
          this.deviceTelemetries = this.telemetries as Models.Telemetries.GatewayMtap7V1Telemetry[];
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (this.selectedElement) {
      if (this.isCircuit(this.selectedElement)) {
        this.FetchCircuitTelemetriesFromTo({
          objectId: this.selectedElement.uuid!,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.elementTelemetries = this.aggregatedTelemetry as Models.Telemetries.SumOfTelemetry;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.FetchGroupTelemetriesFromTo({
          objectId: this.selectedElement.uuid!,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.elementTelemetries = this.aggregatedTelemetry as Models.Telemetries.SumOfTelemetry;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  fetchConsumption() {
    if (!this.selectedDevice && !this.selectedElement) return;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const fromDate = new Date(this.startDate);
    fromDate.setHours(hours);
    fromDate.setMinutes(minutes);
    fromDate.setSeconds(seconds);
    const toDate = new Date(this.endDate);
    toDate.setHours(hours);
    toDate.setMinutes(minutes);
    toDate.setSeconds(seconds);

    const timestampFrom = fromDate.toISOString();
    const timestampTo = toDate.toISOString();

    if (this.selectedDevice) {
      this.fetchDeviceKobize({
        device: this.selectedDevice,
        queryParams: {
          startDate: timestampFrom,
          endDate: timestampTo
        }
      })
        .then(() => {
          if (this.selectedDevice) {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          } else {
            this.consumption += this.energyConsumption;
            this.emission += this.co2Emission;
          }
        });
    } else if (this.selectedElement) {
      if (this.isCircuit(this.selectedElement)) {
        this.FetchCircuitKobize({
          circuit: this.selectedElement as Models.Circuits.Model,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          });
      } else {
        this.FetchGroupKobize({
          group: this.selectedElement as Models.Groups.Model,
          queryParams: {
            startDate: timestampFrom,
            endDate: timestampTo
          }
        })
          .then(() => {
            this.consumption = this.energyConsumption;
            this.emission = this.co2Emission;
          });
      }
    }
  }

  exportToCsv() {
    // let deviceName = '';
    // const {
    //   startDate,
    //   endDate
    // } = this;
    // let fields: string[] = [];
    // let flattenedTelemetries: any[] | undefined = [];
    //
    // function downloadFile(csv: string) {
    //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    //   const link = document.createElement('a');
    //   if (link.download !== undefined) {
    //     const url = URL.createObjectURL(blob);
    //     link.setAttribute('href', url);
    //     link.setAttribute('download', `${deviceName}_${startDate}_${endDate}.csv`);
    //     link.style.visibility = 'hidden';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   }
    // }
    //
    // // use both in chartOptions and for csv fields
    // // check telemetry controllerType
    // // based on controller type use different const from lampTelemetry
    // if (this.selectedDevice) {
    //   fields = [
    //     'id',
    //     'object_id',
    //     'device_type',
    //     'timestamp',
    //     'controller_type',
    //     'set_duty',
    //     // 'activePower',
    //     // 'status',
    //     // 'statusMessage',
    //     // 'activeEnergy',
    //     // 'apparentPower',
    //     // 'apparentEnergy',
    //     // 'loadsidePower',
    //     // 'loadsideEnergy',
    //     // 'controlGearOperatingTime',
    //     // 'controlGearPowerFactor',
    //     // 'controlGearExternalSupplyVoltage',
    //     // 'controlGearExternalSupplyVoltageFrequency',
    //     // 'controlGearTemperature',
    //     // 'controlGearOutputCurrentPercent',
    //     // 'lightSourceVoltage',
    //     // 'lightSourceCurrent',
    //     // 'lightSourceTemperature',
    //     // 'lightSourceOnTime',
    //     // 'deltaActiveEnergy',
    //   ];
    //   deviceName = this.selectedDevice?.object_id;
    //   flattenedTelemetries = this.deviceTelemetries.map((t) => {
    //     const {
    //       id,
    //       object_id,
    //       device_type,
    //       controller_type,
    //       timestamp
    //     } = t;
    //     const telemetryData: any = t.telemetry;
    //     delete telemetryData.timestamp;
    //     this.lampIndices.forEach((e) => {
    //       telemetryData[e] = Math.abs(telemetryData[e]);
    //     });
    //     return {
    //       id,
    //       object_id,
    //       device_type,
    //       controller_type,
    //       timestamp,
    //       ...telemetryData
    //     };
    //   });
    // } else if (this.selectedElement) {
    //   fields = [
    //     'timestamp',
    //   ];
    //   deviceName = this.selectedElement?.name;
    //   flattenedTelemetries = this.elementTelemetries?.data.map((t) => {
    //     const {
    //       timestamp
    //     } = t;
    //     const telemetryData: any = t;
    //     this.lampIndices.forEach((e) => {
    //       telemetryData[e] = Math.abs(telemetryData[e]);
    //     });
    //     return {
    //       timestamp,
    //       ...telemetryData
    //     };
    //   });
    // }
    //
    // fields = fields.concat(this.lampIndices);
    // const opts = { fields };
    // const asyncParser = new AsyncParser(opts);
    // let csv = '';
    // asyncParser.processor
    //   .on('data', (chunk) => {
    //     csv += chunk.toString();
    //   })
    //   .on('end', () => downloadFile(csv))
    //   .on('error', (err) => console.error(err));
    //
    // asyncParser.transform
    //   .on('error', (err) => console.error(err));
    // const data = JSON.stringify(flattenedTelemetries);
    // asyncParser.input.push(data);
    // asyncParser.input.push(null);
  }

  clearState() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
      .substring(0, 10);
    this.endDate = new Date().toISOString()
      .substring(0, 10);
    this.consumption = 0;
    this.emission = 0;
    this.loading = false;
    this.selectedSubDeviceKey = this.subDeviceFirstOption?.value ?? 0;
    this.selectedSubDevicePropertyKey = this.subDevicePropertyFirstOption?.value ?? 0;
    this.deviceTelemetries = [];
    this.elementTelemetries = null;
  }

  @Watch('selectedDevice')
  onSelectedDeviceChange() {
    this.clearState();
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('selectedElement')
  onSelectedElementChange() {
    this.clearState();
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('startDate')
  onStartDateChange() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  @Watch('endDate')
  onEndDateChange() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }

  mounted() {
    this.fetchTelemetries();
    this.fetchConsumption();
  }
}
