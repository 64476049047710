import { timeFormat } from 'd3';

const format = timeFormat('%x, %H:%M');

function dateTimeToStr(isoStr: string): string
function dateTimeToStr(timestamp: number, factor?: number): string
function dateTimeToStr(date: Date): string
function dateTimeToStr(dateTime: string | number | Date, factor?: number): string {
  if (typeof dateTime === 'number' && factor) {
    return format(new Date(dateTime * 1000));
  }
  if (Object.prototype.toString.call(dateTime) === '[object Date]') {
    return format(dateTime as Date);
  }
  return format(new Date(dateTime));
}

export {
  dateTimeToStr
};
