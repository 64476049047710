import { CircuitGroupProporties, TelemetryIndicesMap } from '@/types/telemetry';

const circuitGroupIndicesNames = [
  'activePower',
];

const circuitGroupTelemetryIndices: TelemetryIndicesMap<CircuitGroupProporties> = {
  activePower: {
    min: 0, max: 0, unit: 'W'
  },
};

export default {
  circuitGroupTelemetryIndices,
  circuitGroupIndicesNames
};
