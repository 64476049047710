














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @group Analytics Card
 * Time meter displaying time elapsed in _h_m_s format
 */
export default class ClassicMeter extends Vue {
  // time elapsed in miliseconds
  @Prop({ type: Number, required: true }) readonly value!: number

  @Prop({ type: String, required: true }) readonly unit!: string

  @Prop({ type: String, required: false }) readonly name!: string
}
